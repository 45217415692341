
//theme.js
const theme = {
  colors: {
    primary: {
      light: 'text-teal-300',
      DEFAULT: 'text-teal-400',
      dark: 'text-teal-500',
      hover: 'hover:text-teal-300',
    },
    secondary: {
      light: 'text-indigo-300',
      DEFAULT: 'text-indigo-400',
      dark: 'text-indigo-500',
      hover: 'hover:text-indigo-300',
    },
    status: {
      success: {
        text: 'text-green-400',
        bg: 'bg-green-500/20',
        border: 'border-green-500/50',
        glow: 'shadow-lg shadow-green-500/20 hover:shadow-green-500/30',
      },
      error: {
        text: 'text-red-400',
        bg: 'bg-red-500/20',
        border: 'border-red-500/50',
        glow: 'shadow-lg shadow-red-500/20 hover:shadow-red-500/30',
      },
      warning: {
        text: 'text-yellow-400',
        bg: 'bg-yellow-500/20',
        border: 'border-yellow-500/50',
        glow: 'shadow-lg shadow-yellow-500/20 hover:shadow-yellow-500/30',
      },
      info: {
        text: 'text-blue-400',
        bg: 'bg-blue-500/20',
        border: 'border-blue-500/50',
        glow: 'shadow-lg shadow-blue-500/20 hover:shadow-blue-500/30',
      },
    },
    text: {
      primary: 'text-white',
      secondary: 'text-gray-300',
      muted: 'text-gray-400',
    },
    gradients: {
      primary: 'bg-gradient-to-r from-teal-400 to-teal-600',
      secondary: 'bg-gradient-to-r from-indigo-400 to-indigo-600',
      success: 'bg-gradient-to-r from-green-400 to-green-600',
      error: 'bg-gradient-to-r from-red-400 to-red-600',
      warning: 'bg-gradient-to-r from-yellow-400 to-yellow-600',
      info: 'bg-gradient-to-r from-blue-400 to-blue-600',
      dark: 'bg-gradient-to-br from-gray-900/95 via-gray-900/90 to-gray-900/95',
      glow: 'bg-[radial-gradient(ellipse_at_top,rgba(20,184,166,0.15),transparent_50%)]',
    },
    effects: {
      glow: {
        primary: 'shadow-lg shadow-teal-500/20 hover:shadow-teal-500/30',
        secondary: 'shadow-lg shadow-indigo-500/20 hover:shadow-indigo-500/30',
        success: 'shadow-lg shadow-green-500/20 hover:shadow-green-500/30',
        error: 'shadow-lg shadow-red-500/20 hover:shadow-red-500/30',
        warning: 'shadow-lg shadow-yellow-500/20 hover:shadow-yellow-500/30',
        info: 'shadow-lg shadow-blue-500/20 hover:shadow-blue-500/30',
        primaryGlow: 'shadow-lg shadow-teal-500/20 hover:shadow-teal-500/30',
      },
      glass: {
        light: 'backdrop-blur-sm bg-white/10',
        dark: 'backdrop-blur-sm bg-gray-900/80',
      },
    },
  },
  components: {
    layout: {
      background: 'min-h-screen bg-gray-900 relative overflow-hidden',
      backgroundImage: 'fixed inset-0 bg-cover bg-center bg-no-repeat z-0',
      backgroundOverlay: 'fixed inset-0 bg-gradient-to-br from-gray-900/95 via-gray-900/90 to-gray-900/95 backdrop-blur-sm',
      backgroundGrid: 'fixed inset-0 bg-grid-white/[0.02] bg-[size:60px_60px]',
      content: 'relative z-10 min-h-screen',
    },
    button: {
      base: 'rounded-xl transition-all duration-200 flex items-center justify-center gap-2 font-medium',
      sizes: {
        sm: 'px-3 py-1.5 text-sm',
        md: 'px-4 py-2 text-base',
        lg: 'px-6 py-3 text-lg',
      },
      variants: {
        primary: 'bg-gradient-to-r from-teal-500 to-teal-600 text-white hover:from-teal-400 hover:to-teal-500 shadow-lg shadow-teal-500/20 hover:shadow-teal-500/30',
        secondary: 'bg-gradient-to-r from-indigo-500 to-indigo-600 text-white hover:from-indigo-400 hover:to-indigo-500 shadow-lg shadow-indigo-500/20 hover:shadow-indigo-500/30',
        outline: 'border border-gray-700/50 hover:border-teal-500/30 text-gray-300 hover:text-white',
        ghost: 'text-gray-300 hover:text-white hover:bg-gray-700/30',
        glass: 'backdrop-blur-sm bg-white/10 border border-white/20 hover:bg-white/20 text-white',
        glassDark: 'backdrop-blur-sm bg-gray-900/80 border border-gray-700/50 hover:bg-gray-800/90 text-gray-300',
        link: 'text-teal-400 hover:text-teal-300 underline-offset-4 hover:underline',
        icon: 'p-2 rounded-lg hover:bg-gray-700/30',
        floating: 'shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all',
      },
      states: {
        loading: 'opacity-80 cursor-wait',
        disabled: 'opacity-50 cursor-not-allowed pointer-events-none',
        active: 'transform scale-95',
      },
    },
    card: {
      base: 'rounded-xl backdrop-blur-sm bg-gray-800/30 border border-gray-700/50 transition-all duration-300',
      header: 'px-6 py-4 border-b border-gray-700/50',
      body: 'p-6',
      footer: 'px-6 py-4 border-t border-gray-700/50',
      variants: {
        glass: 'backdrop-blur-sm bg-white/10 border border-white/20 hover:bg-white/20',
        glassDark: 'backdrop-blur-sm bg-gray-900/80 border border-gray-700/50 hover:bg-gray-900/90',
        floating: 'shadow-xl hover:shadow-2xl transform hover:-translate-y-1 transition-all',
        bordered: 'border-2 border-gray-700/50 hover:border-teal-500/30',
        gradient: 'bg-gradient-to-br from-gray-800/50 to-gray-900/50',
        neon: 'border-2 border-teal-500/50 shadow-lg shadow-teal-500/20 hover:shadow-teal-500/40 hover:border-teal-400/60',
        frost: 'backdrop-blur-md bg-white/5 border border-white/10 hover:bg-white/10',
        rainbow: 'bg-gradient-to-br from-pink-500/20 via-purple-500/20 to-teal-500/20 hover:from-pink-500/30 hover:via-purple-500/30 hover:to-teal-500/30',
        spotlight: 'bg-radial-gradient from-gray-800/80 to-gray-900/90 hover:from-gray-800/90',
        minimal: 'bg-transparent border border-gray-700/30 hover:bg-gray-800/20',
        elevated: 'bg-gray-800/40 shadow-lg hover:shadow-xl hover:bg-gray-800/50 transform hover:-translate-y-0.5',
        interactive: 'cursor-pointer transition-all duration-300 hover:scale-[1.02] active:scale-[0.98]',
      },
      sizes: {
        xs: 'p-2',
        sm: 'p-4',
        md: 'p-6',
        lg: 'p-8',
        xl: 'p-10',
      },
      states: {
        active: 'ring-2 ring-teal-500/50',
        disabled: 'opacity-50 pointer-events-none',
        loading: 'animate-pulse',
      },
    },
    input: {
      base: 'block w-full rounded-xl bg-gray-700/50 border border-gray-600/50 px-4 py-3 text-gray-300 placeholder-gray-400 focus:border-teal-500 focus:ring-2 focus:ring-teal-500/50 transition-all duration-300',
      error: 'border-red-500/50 focus:border-red-500 focus:ring-red-500/50',
      disabled: 'opacity-50 cursor-not-allowed',
      variants: {
        outlined: 'bg-transparent border border-gray-700/50 focus:border-teal-500',
        filled: 'bg-gray-700/50 border-transparent focus:bg-gray-700/70',
        glass: 'backdrop-blur-sm bg-white/10 border border-white/20',
        glassDark: 'backdrop-blur-sm bg-gray-900/80 border border-gray-700/50',
      },
    },
    badge: {
      base: 'inline-flex items-center font-medium',
      variants: {
        primary: 'bg-teal-500/20 text-teal-400',
        success: 'bg-green-500/20 text-green-400',
        error: 'bg-red-500/20 text-red-400',
        warning: 'bg-yellow-500/20 text-yellow-400',
        info: 'bg-blue-500/20 text-blue-400',
      },
      sizes: {
        sm: 'px-2 py-0.5 text-xs',
        md: 'px-2.5 py-1 text-sm',
        lg: 'px-3 py-1.5 text-base',
      },
      shapes: {
        rounded: 'rounded-md',
        pill: 'rounded-full',
        square: 'rounded-none',
      },
    },
    toast: {
      base: 'fixed bottom-4 right-4 z-50 rounded-lg shadow-lg max-w-md',
      variants: {
        info: 'bg-blue-500/20 border border-blue-500/50 text-blue-400',
        success: 'bg-green-500/20 border border-green-500/50 text-green-400',
        warning: 'bg-yellow-500/20 border border-yellow-500/50 text-yellow-400',
        error: 'bg-red-500/20 border border-red-500/50 text-red-400',
      },
    },
  },
  typography: {
    h1: 'text-4xl font-bold bg-gradient-to-r from-white via-teal-200 to-teal-400 bg-clip-text text-transparent tracking-tight',
    h2: 'text-3xl font-bold text-white tracking-tight',
    h3: 'text-2xl font-semibold text-white tracking-tight',
    h4: 'text-xl font-semibold text-white tracking-tight',
    h5: 'text-lg font-medium text-white tracking-tight',
    h6: 'text-base font-medium text-white tracking-tight',
    body1: 'text-base text-gray-300 leading-relaxed',
    body2: 'text-sm text-gray-400 leading-relaxed',
    caption: 'text-sm text-gray-500',
    overline: 'text-xs uppercase tracking-wider text-gray-500',
    lead: 'text-xl text-gray-300 leading-relaxed',
    gradient: 'bg-gradient-to-r from-white via-teal-200 to-teal-400 bg-clip-text text-transparent',
  },
  layout: {
    container: 'container mx-auto px-4',
    maxWidths: {
      sm: 'max-w-screen-sm',
      md: 'max-w-screen-md',
      lg: 'max-w-screen-lg',
      xl: 'max-w-screen-xl',
      '2xl': 'max-w-screen-2xl',
    },
    grid: {
      cols1: 'grid grid-cols-1 gap-4',
      cols2: 'grid grid-cols-1 md:grid-cols-2 gap-4',
      cols3: 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4',
      cols4: 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4',
    },
  },
  animation: {
    transition: {
      fast: 0.15,
      normal: 0.3,
      slow: 0.5,
    },
    easing: {
      easeInOut: [0.4, 0, 0.2, 1],
      easeOut: [0, 0, 0.2, 1],
      easeIn: [0.4, 0, 1, 1],
    },
    spring: {
      gentle: {
        type: 'spring',
        stiffness: 100,
        damping: 20,
      },
      bounce: {
        type: 'spring',
        stiffness: 300,
        damping: 15,
      },
      elastic: {
        type: 'spring',
        stiffness: 400,
        damping: 10,
      },
      slow: {
        type: 'spring',
        stiffness: 50,
        damping: 15,
      },
    },
  },
};

export default theme;