import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserProvider } from "./contexts/UserContext";
import { OverlayProvider } from "./contexts/OverlayContext";
import { ChargeSheetProvider } from "./contexts/ChargeSheetContext";
import { ActiveHospitalLocationProvider } from "./contexts/ActiveHospitalLocationContext";
import { ChargeSheetDetailsProvider } from "./contexts/ChargeSheetDetailsContext";
import { ThemeProvider } from "./theme/ThemeContext";
import "./App.css";

// Lazy load components
const MainContent = lazy(() => import("./MainContent"));
const WebsiteComponents = {
  LandingPage: lazy(() => import("./Website/LandingPage")),
  AboutUs: lazy(() => import("./Website/AboutUs")),
  ContactUs: lazy(() => import("./Website/ContactUs")),
  FAQ: lazy(() => import("./Website/FAQ")),
  ConsultingServices: lazy(() => import("./Website/ConsultingPage/ConsultingServices")),
  Login: lazy(() => import("./components/login/Login")),
  Signup: lazy(() => import("./components/login/Signup")),
  ErrorPage: lazy(() => import("./ErrorPage")),
};

// Loading component
const LoadingFallback = () => (
  <div className="min-h-screen w-full flex items-center justify-center bg-gray-900 fixed inset-0">
    <div className="flex flex-col items-center space-y-4">
      <div className="w-16 h-16 border-4 border-teal-500 border-t-transparent rounded-full animate-spin"></div>
      <div className="text-teal-400 font-medium animate-pulse">Loading...</div>
    </div>
  </div>
);

const App = () => {
  useEffect(() => {
    // Hybrid viewport handling
    const existingMeta = document.querySelector('meta[name="viewport"]');
    const initialContent = existingMeta ? existingMeta.content : 'width=device-width, initial-scale=1';
    
    const handleResize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      
      // Only adjust viewport for mobile
      if (window.innerWidth < 768) {
        existingMeta.content = `${initialContent}, maximum-scale=1, user-scalable=no, viewport-fit=cover`;
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
      <ThemeProvider>
        <UserProvider>
          <OverlayProvider>
            <ChargeSheetProvider>
              <ActiveHospitalLocationProvider>
                <ChargeSheetDetailsProvider>
                  <Router>
                    <Suspense fallback={<LoadingFallback />}>
                      <div className="app-container">
                        <div className="content-wrapper">
                          <Routes>
                            {/* Website Routes */}
                            <Route path="/" element={<WebsiteComponents.LandingPage />} />
                            <Route path="/about" element={<WebsiteComponents.AboutUs />} />
                            <Route path="/contact" element={<WebsiteComponents.ContactUs />} />
                            <Route path="/faq" element={<WebsiteComponents.FAQ />} />
                            <Route path="/consulting" element={<WebsiteComponents.ConsultingServices />} />
                            <Route path="/login" element={<WebsiteComponents.Login />} />
                            <Route path="/signup" element={<WebsiteComponents.Signup />} />
                            
                            {/* App Routes */}
                            <Route path="/app/*" element={<MainContent />} />
                            
                            {/* Error Routes */}
                            <Route path="*" element={<WebsiteComponents.ErrorPage />} />
                          </Routes>
                        </div>
                      </div>
                    </Suspense>
                  </Router>
                </ChargeSheetDetailsProvider>
              </ActiveHospitalLocationProvider>
            </ChargeSheetProvider>
          </OverlayProvider>
        </UserProvider>
      </ThemeProvider>
  );
};

export default App;
